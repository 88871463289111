//
// authentication.scss
//

// authentication home icon
.home-btn {
    position: absolute;
    top: 15px;
    right: 25px;
}
.green-back {
    cursor: pointer;
    border: 1px solid #e5e5e5;

    color: $dark-green;
    background: #447e632b;
    & .solid-grey-circle {
        width: 39px;
        height: 39px;

        background-color: #efefef;
    }
}
// h6 {
//     width: 100%;
//     text-align: center;
//     border-bottom: 1px solid #ced4da;
//     line-height: 0.1em;
//     margin: 10px 0 20px;
//  }

//  h6 span {
//      background:#fff;
//      padding:0 10px;
//  }

.logo-fixed {
    img {
        width: 160px;
    }
    display: flex;
    align-items: center;
    padding-left: 3%;
    position: fixed;
    z-index: 1;
    background-color: #fff;
    height: 70px;
    width: 100%;
    background: #ffffff;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.06);
}
.about-page-auth {
    padding-top: 85px;
    padding-bottom: 231px;
    .accordian-container {
        .card {
            box-shadow: none;
            .card-header {
                padding: 0;
                background-color: #fff;
            }
        }
    }
}
.custom-google-btn,
.custom-linkdn-btn {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    padding: 15px;
}
.custom-google-btn,
.custom-linkdn-btn {
    & a {
        color: $text-black;
    }
}
.input-group-append {
    position: absolute;
    right: 10px;
    top: 4px;
    cursor: pointer;
    z-index: 111 !important;
}
.signup-description {
    font-size: 16px;
    padding-bottom: 20px;
    color: #111111;
    font-weight: 300;
    line-height: 20px;
}
.pass-err-desc {
    color: $light-text-color;
}
.pw-match-success {
    color: #34c38f;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
}

.account-pages .left-section {
    padding: 20px 50px;
    height: 100vh;
    background-color: #fff;
    width: 50%;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url("../../../images/ZunavishBackGround.png");
    display: flex;
    justify-content: center;
    align-items: center;
}
.account-pages .right-section {
    padding: 20px;
    height: 100vh;
    width: 50%;
    background-repeat: no-repeat;
    background-image: url("../../../images/signup.png");
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: 100% 100%;
    background-color: #fff;
}
.account-pages {
    .login-form {
        max-width: 400px;
        margin-left: 30%;
    }
    .login-heading {
        margin-left: 30%;
        color: #174731;
        font-weight: 700;
    }
    .text-container {
        position: absolute;
        top: 80%;

        margin-left: 3%;
    }
    .company-text-bold {
        img {
            width: 295px;
        }
    }
    .form-group {
        ul {
            li {
                img {
                    width: 15px;
                }
            }
        }
    }
}

//Upload Resume
.upload-file {
    & .dropzone {
        min-height: 150px;
        cursor: pointer;
        border: 2px dashed #e5e5e5;
        border-radius: 4px;
        box-sizing: border-box;
        border-radius: 4px;
        & .dz-message {
            padding: 10px;
        }
        & .upload-resume-text {
            font-weight: 700;
            color: $dark-green;
            text-decoration-line: underline;
        }
    }
}

//Wizrad
.twitter-bs-wizard {
    & .twitter-bs-wizard-nav {
        background-color: transparent;
        & .nav-item {
            & .nav-link {
                & .step-number {
                    width: 25px;
                    height: 25px;
                    background-image: url("../../../images/wizard_dot.png");
                    background-size: cover;
                    color: transparent;
                    border: none;
                    background-color: transparent;
                    //border: 1px solid $text-black;
                    // color: $text-black;;
                    &::before {
                        content: "";
                        position: absolute;
                        left: 63%;
                        width: 76%;
                        top: 12px;
                        height: 2px;
                        color: #fff;
                        background-color: transparent;
                        border-top: 1px solid $dark-green;
                    }
                    &.pd-width,
                    &.ed-width,
                    &.empd-width {
                        width: 30px;
                        height: 30px;
                        background-image: url("../../../images/selected_wizard_dot.png");
                        background-size: cover;
                        color: transparent;
                        border: none;
                    }
                    // &.solid-line-circle{
                    //     background-image: url("../../../images/selected_wizard_dot.png");
                    //     background-size: cover;
                    //     color: transparent;
                    // }
                }
                &:hover {
                    color: $text-black !important;
                }
                padding: 0px;
                position: relative;
            }
            &:last-child .step-number::before {
                display: none;
            }
        }
    }
}

.company-info .twitter-bs-wizard .twitter-bs-wizard-nav .nav-item .nav-link .step-number::before {
    left: 57%;
    width: 86%;
}

.tab-heading-light {
    color: #e6e6e6;
}

.cust-radio-label {
    padding-right: 50px;
}
.company-details .cust-select {
    height: auto;
    border: none;
    width: 40%;
    border-radius: 0px;
    border-bottom: 1px solid #ced4da;
    padding: 0px 10px;
}
.company-details {
    .upldoad-logo {
        img {
            width: 13px;
        }
    }
    .company-logo-img {
        width: 55px;
        position: relative;
        img {
            width: 55px;
            height: 55px;
            border-radius: 4px;
            box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
        }
        .img-close {
            cursor: pointer;
            width: 20px;
            height: 20px;
            background: #eb5757;
            border-radius: 100%;
            color: #fff;
            font-size: 20px;
            font-weight: 500;
            position: absolute;
            right: -11px;
            top: -9px;
            display: flex;
            text-align: center;
            justify-content: center;
            align-items: center;
        }
    }
}
.about-company {
    .css-1pahdxg-control {
        border-color: #ced4da;
        box-shadow: none;
        &:hover {
            border-color: #ced4da;
        }
    }
}

.added-row {
    //border: 1px solid #EEEEEE;
    border-radius: 4px;
    padding: 20px 15px;
    color: $dark-green;
    background: #447e632b;
    border-radius: 4px;
    & .solid-grey-circle {
        width: 39px;
        height: 39px;
        border-radius: 240px;
        background-color: #efefef;
    }
}
.react-datepicker-wrapper .form-control[readonly] {
    opacity: 0.7;
}
textarea {
    resize: none;
}
// .phone-number-input{ padding-left: 66px;}
.phone-number-select {
    height: 100% !important;
    padding: 5px;
    width: 90%;
    &.form-control:focus {
        background-color: transparent;
    }
}
.add-other {
    color: $text-black;
    text-decoration-line: underline;
}

.more-about-yourself textarea.form-control,
.work-related textarea.form-control,
.job-relatedf textarea.form-control {
    height: 45px;
}
//Form Input

//form error
.account-pages .form-control.is-invalid,
.add-user-modal .form-control.is-invalid {
    background-image: none;
    &:focus {
        box-shadow: none;
        border-bottom-color: #f46a6a;
        background-image: none;
    }
}
// .account-pages .form-group .form-control{
//     border: none;
//     border-bottom: 1px solid $border-bottom-color;
//     padding-left: 0rem;
//     border-radius: 0rem;
//     padding-top: 0rem;
//     padding-bottom: 0rem;
// }

.educational-details .select-container .css-1hb7zxy-IndicatorsContainer,
.employment-details .select-container .css-1hb7zxy-IndicatorsContainer {
    display: block;
}

.educational-details .select-container .css-tlfecz-indicatorContainer,
.employment-details .select-container .css-tlfecz-indicatorContainer {
    color: hsl(0, 0%, 80%);
}
body[data-layout="horizontal"] .page-content.job-assessment {
    background-color: #e5e5e5;
}
.job-assessment {
    .job-footer {
        position: fixed;
        bottom: 0;
        height: 50px;
        z-index: 1;
        background-color: #d5e8e0;
        width: 100%;
        & .btn-outline-green {
            background-color: #fff;
            &:hover {
                color: $dark-green;
            }
        }
    }
    .form-control:disabled,
    .form-control[readonly] {
        opacity: 0.6;
    }
}
.job-wizard,
.company-wizard {
    .card {
        box-shadow: none;
        border: 1px solid #e5e5e5;
    }
}

body[data-layout="horizontal"] .page-content.company-info {
    background-color: #f6f6f6;
}

.tabs {
    width: 250px;
    height: 250px;
    background-color: $white;
    border: 1px solid #e5e5e5;
    border-radius: 4px;
    cursor: pointer;
    padding: 15px;
    &.selected {
        background: #d5e8e0;
    }
}

body[data-layout="horizontal"] .page-content.grey-bg {
    background-color: #f6f6f6;
}
.upload-icon-container {
    img {
        width: 15px;
    }
}
