$dark-green: #213d30;
$light-green: #74b496;
$card-background-color: #f3f3f3;
$profile-border-color: #dde1df;
$light-text-color: #6f6f6f;
$text-black: #000000;
$border-bottom-color: #eeeeee;
$btn-grey-background: #c4c4c4;
$light-bg-color: #d5e8e0;

.label-required {
    color: #f46a6a;
}

// .form-group label{
//     color:$light-text-color;
// }
// .form-group input,.form-group select{
//     border: none;
//     border-radius: 0px;
//     border-bottom: 1px solid #E0E0E0;
//     padding: 0px 0px;
//     height: auto;
// }
@font-face {
    font-family: "Helvetica";
    font-weight: normal;
    font-style: normal;
    src: url("../../assets/fonts/Helvetica.ttf") format("truetype");
}

body {
    font-family: "Helvetica";
}

.text-color-light {
    color: $light-text-color;
}

.form-group input::placeholder {
    color: $light-text-color;
    font-weight: 300;
}

.font-weight-200 {
    font-weight: 200;
}

.noti-icon .badge {
    left: 23px;
}

.dataTables_filter,
.dataTables_paginate {
    float: right;
}

.rdw-editor-main {
    border: 1px solid #eeeef5;
    height: 239px;
}

.fcYNFc {
    background-color: #ffffff !important;
}

.task-box {
    border: 1px solid #e1e1e6;
}

.react-datepicker-wrapper {
    width: 100% !important;
}

.ReactModal__Overlay {
    z-index: 1001 !important;
}

.fc-event .fc-content {
    padding: 5px;
    color: #fff;
}

.chat-conversation .right .conversation-list {
    margin-right: 15px;
}

.external-event {
    &:hover {
        cursor: pointer;
    }
}

.rangeslider-horizontal .rangeslider__fill {
    background-color: #7cb342 !important;
}

//button background color
.bg-green {
    background-color: #213d30;
    color: #fff;

    &:hover {
        color: #fff;
    }

    &:focus {
        box-shadow: none;
    }
}

.bg-grey {
    background-color: $btn-grey-background;
    color: $text-black;

    &:hover {
        color: $text-black;
    }

    &:disabled {
        opacity: 0.3;
    }

    &:focus {
        box-shadow: none;
    }
}

.btn-outline-shadow {
    color: #213d30;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    background-color: #fff;

    &:hover {
        color: #213d30;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
        border-radius: 4px;
    }

    &:focus {
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
    }
}

.btn-outline-green {
    color: #213d30;
    border-color: #213d30;

    &:hover {
        color: #fff;
        background-color: #213d30;
        border-color: #213d30;
    }

    &:focus {
        box-shadow: none;
    }

    &:disabled {
        opacity: 0.1;
    }
}

.btn-outline-grey {
    color: $text-black;
    border-color: #4f4f4f;

    &:focus {
        box-shadow: none;
    }

    &:disabled {
        opacity: 0.1;
    }
}

//text color
.green-text-color {
    color: #213d30;

    &:hover {
        color: #213d30;
    }
}

.font-dark-green {
    color: #213d30;
}

.light-green-text {
    color: $light-green;

    &:hover {
        color: $light-green;
    }
}

.light-green-text-color {
    color: #447e63;

    &:hover {
        color: #447e63;
    }
}

//Date Css
.cust-date-icon {
    position: absolute;
    right: 5px;
    top: 4px;
    font-size: 18px;
    cursor: pointer;
}

.react-datepicker__input-container input::placeholder {
    color: #e0e0e0;
}

//Toast Notification
#toast-container > .toast-error {
    background-image: none !important;
}

.cursor-pointer {
    cursor: pointer;
}

///Body

body[data-layout-size="boxed"] {
    background-color: #f6f6f6;
    color: $dark-green;
}

//page-content padding

body[data-layout="horizontal"] {
    .page-content {
        padding-top: #{$grid-gutter-width};
        // background-color: $white;
    }
}

//top header
body[data-layout="horizontal"][data-topbar="colored"] #page-topbar {
    background-color: $dark-green;
}

#page-topbar {
    box-shadow: none;
}

.app-search {
    width: 55%;
    margin-left: 20%;
}

body[data-layout="horizontal"][data-topbar="colored"] .app-search .form-control {
    border-radius: 2px;
    background-color: #fff;
    color: #495057;
    padding-left: 15px;
    padding-right: 40px;
    height: 30px;
}

.app-search span {
    left: unset;
    right: 13px;
}

body[data-layout="horizontal"][data-topbar="light"] a.header-item {
    &:hover,
    &.active {
        color: $dark-green;
        border-bottom: 3px solid $dark-green;
        // font-weight: bold;
        text-shadow: 0 0 0.65px $dark-green, 0 0 0.65px $dark-green;
        padding-bottom: 22px;
    }
}

body[data-layout="horizontal"][data-topbar="light"] .app-search span,
body[data-layout="horizontal"][data-topbar="light"] .app-search input.form-control::-webkit-input-placeholder {
    color: #74788d;
}

.navbar-header .logo.logo-dark {
    img {
        width: 160px;
    }
}

// React Table Css
.table.react-table {
    & tbody {
        background-color: $card-background-color;
    }

    & th,
    td {
        border: none;
    }

    & td {
        padding-top: 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid #e8e8e8;
        position: relative;
        vertical-align: middle;
    }
}

// link underline

.sign-in-link {
    color: $dark-green;
    border-bottom: 1px solid $dark-green;

    &:hover {
        color: $dark-green;
        border-bottom: 1px solid $dark-green;
    }
}

.text-under-line {
    text-decoration-line: underline;
}

body[data-layout-size="boxed"] #layout-wrapper {
    box-shadow: none;
}

.twitter-bs-wizard-tab-content {
    min-height: 160px;
}

.dropdown-menu {
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.15);
    border-radius: 4px 0px 4px 4px;

    a {
        color: $light-green;
    }
}

.select-container .css-1hb7zxy-IndicatorsContainer,
.select-container .css-1hb7zxy-IndicatorsContainer {
    display: block;
}

.select-container .css-tlfecz-indicatorContainer,
.select-container .css-tlfecz-indicatorContainer {
    color: hsl(0, 0%, 80%);
}

.select-container.multiple-select .css-1hb7zxy-IndicatorsContainer .css-tlfecz-indicatorContainer,
.select-container.multiple-select .css-1hb7zxy-IndicatorsContainer .css-1gtu0rj-indicatorContainer {
    display: none;
}

.select-container.multiple-select .css-1rhbuit-multiValue {
    background-color: $light-green;
    border-radius: 12px;
    color: $white;

    .css-12jo7m5 {
        color: $white;
    }
}

.select-container.multiple-select .css-xb97g8:hover {
    background-color: transparent;
    color: #ffffff;
}

.degree_courses .select-container .css-tlfecz-indicatorContainer,
.company-details .select-container .css-tlfecz-indicatorContainer {
    color: hsl(0, 0%, 40%);
}

.degree_courses .select-container .css-1hb7zxy-IndicatorsContainer,
.company-details .select-container .css-1hb7zxy-IndicatorsContainer {
    display: block;
}

.form-control:disabled,
.form-control[readonly] {
    opacity: 0.3;
}

.job-form .year-text {
    position: absolute;
    right: 11%;
    top: 55%;
}

.job-form,
.job-dashbord,
.application-tab {
    .skill-container {
        min-width: 45px;
        padding: 0px 5px;
        border-radius: 45px;
        position: relative;
        color: $white;
        background-color: #74b496;
        font-weight: 300;
        font-size: 14px;
    }
}

.detail-tab {
    .skill-container {
        padding: 5px 10px;
        border-radius: 45px;
        position: relative;
        color: $white;
        background-color: #74b496;
        font-weight: 300;
        font-size: 14px;
    }
}

.cut-text-ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

/* React Tag Styles Start */

div.ReactTags__tags {
    border: 1px solid #e5e5e5;
    border-radius: 4px;
    padding: 4px;
    max-height: 200px;
    overflow-y: auto;
    overflow-x: hidden;
    position: static;
}

/* Styles for the input */
div.ReactTags__tagInput {
    width: 100%;
    display: inline-block;
    border: none !important;
}

div.ReactTags__tagInput:focus {
    border: none !important;
    outline: none;
}

div.ReactTags__tagInput input.ReactTags__tagInputField,
div.ReactTags__tagInput input.ReactTags__tagInputField:focus {
    height: 31px;
    margin: 0;
    font-size: 12px;
    width: 100%;
    // border: 1px solid #eee;
    padding: 0 4px;
    border: none !important;
    border-color: transparent;
    outline: none;
}

/* Styles for selected tags */
div.ReactTags__selected span.ReactTags__tag {
    color: $white;
    background-color: #74b496;
    font-size: 12px;
    display: inline-block;
    padding: 5px;
    margin: 2px 5px;
    cursor: move;
    border-radius: 20px;
}

div.ReactTags__selected a.ReactTags__remove {
    color: $white;
    margin-left: 5px;
    cursor: pointer;
}

/* Styles for suggestions */
div.ReactTags__suggestions {
    position: absolute;
    z-index: 1;
    width: 100%;
    overflow: visible;
}

div.ReactTags__suggestions ul {
    list-style-type: none;
    box-shadow: 0.05em 0.01em 0.5em rgba(0, 0, 0, 0.2);
    background: white;
    max-height: 300px;
    overflow: auto;
    letter-spacing: normal;
    width: 100%;
    border-radius: 4px;
    border: 1px solid #e5e5ee;
    padding-left: 0px;
}

div.ReactTags__suggestions li {
    border-bottom: 1px solid #ddd;
    padding: 5px;
    margin: 0;
    font-size: 12px;
    font-weight: 400;
    margin-left: 0px;
    font-family: inherit;
}

div.ReactTags__suggestions li mark {
    text-decoration: underline;
    background: none;
    margin-left: 0px;
}

div.ReactTags__suggestions ul li.ReactTags__activeSuggestion {
    background: #b7cfe0;
    cursor: pointer;
    margin-left: 0px;
}

/* React Tag Styles End */
.font-weight-100 {
    font-weight: 100;
}

.blue-status-text {
    font-size: 11px;
    font-weight: 300;
    background-color: #4d4af2;
    color: $white;
    border-radius: 20px;
    padding: 3px 5px;
}

.green-status-text {
    font-size: 11px;
    font-weight: 300;
    background-color: #174731;
    color: $white;
    border-radius: 20px;
    padding: 3px 5px;
}

.orange-status-text {
    font-size: 11px;
    font-weight: 300;
    background-color: #f2994a;
    color: $white;
    border-radius: 20px;
    padding: 3px 5px;
}

.red-status-text {
    font-size: 11px;
    font-weight: 300;
    background-color: #eb5757;
    color: $white;
    border-radius: 20px;
    padding: 3px 5px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.info-timeline li:not(:first-of-type) div.list-dot::before {
    position: absolute;
    border: 1px solid #999;
    width: 0;
    height: 50px;
    display: block;
    content: "";
    left: 50%;
    z-index: 1;
    top: -54px;
    margin-left: -1px;
}

.accordin-open-container .verti-timeline {
    border-left: 3px solid $dark-green;
}

.list-dot {
    width: 15px;
    height: 15px;
    margin-right: 5px;
    margin-top: 5px;
    background: $dark-green;
    border-radius: 100%;
    // position: relative;
    // border: 2px solid #999;
    // border-radius: 100%;
    // width: 50px;
    // line-height: 50px;
    // text-align: center;
    // margin-top: 50px;
    // background-color: #fff;
    // z-index: 2;
    // display: inline-block;
}

.row-expand {
    background-color: #ffffff;
    padding: 2em;
    border: 1px solid #e5e5e5;
    border-top: none;
    border-radius: 4px;
}

.collapse-shadow {
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
}

.circle_plus {
    border: 1px solid #213d30;
    border-radius: 100%;
    width: 20px;
    height: 20px;
    text-align: center;
}

.flexbox {
    display: flex;
    justify-content: flex-end;
}

.notification_container {
    .dropdown-menu a {
        &:hover {
            color: #74b496;
        }
    }

    .btn:focus {
        box-shadow: none;
    }
}

.btn-primary {
    background-color: $dark-green;
    border-color: $dark-green;
}

.web_display {
    display: flex;
}

.mobileDevice {
    .table-responsive {
        background-color: #fff;
    }

    .account-pages {
        .right-section {
            display: none;
        }

        .left-section {
            width: 100%;
            padding: 20px 0px;
        }

        .login-heading {
            margin-left: 0;
        }

        .login-form {
            margin-left: 0;
        }

        .form_wrapper {
            padding-left: 0 !important;
        }

        .mobile_left_padding {
            padding-left: 1rem !important;
        }
    }

    .mobile_dashboard_btn {
        margin-top: 1em;
        margin-bottom: 1em;
        justify-content: center;
    }

    .navbar-header .logo.logo-dark img {
        width: 111px;
    }

    .navbar-header {
        padding-left: 12px;
        padding-right: 0;

        .navbar-brand-box {
            padding: 0;
        }

        .header-font .btn {
            padding: 0.47rem 0.5rem;
        }
    }

    .btn.header-item {
        padding: 0.47rem 0.5rem;
    }

    .mobile_profile_header {
        margin-bottom: 1rem !important;
    }

    .web_display {
        display: block;
    }

    .mob_display {
        display: flex;
    }

    .mobile_top_spacing {
        margin-top: 1em;
    }

    .college-profile .right-pencil-section {
        top: -145px;
    }

    .college-profile .mobile_profile_email {
        padding-top: 1rem !important;
    }

    .account-setting {
        margin-top: 90px !important;

        .mobile_padding {
            padding-left: 1rem !important;
            padding-right: 1rem !important;
        }

        .top-container .company-info {
            height: auto;
            min-height: 60px;
        }

        .mobile_account_header {
            margin-bottom: 1rem !important;
        }
    }

    .mobile_no_left_margin {
        margin-left: 0px !important;
    }

    .company-info .about-page-auth .twitter-bs-wizard .twitter-bs-wizard-nav .nav-item .nav-link .step-number::before {
        left: 63%;
        width: 76%;
    }
}

body[data-layout="horizontal"].mobileDevice .page-content {
    padding-top: 80px;
}

@media (max-width: 380px) {
    .navbar-brand-box {
        display: block;
    }
}

.toast-top-right .toast-success {
    border: 2px solid #34c38f !important;
    background-color: #34c38f !important;
}

.text_light_green {
    color: #34c38f;
}

.text_light_green:hover {
    color: #248763;
}

.font-size-36 {
    font-size: 36px !important;
}

.font-size-38 {
    font-size: 38px !important;
}

.toast-top-right .toast.toast-error {
    background-color: #f46a6a;
}

.circled-box {
    background: #74b496;
    border-radius: 54px;
    color: white;
}
