body[data-layout="horizontal"] .page-content.job-details,
body[data-layout="horizontal"] .page-content.job-dashbord{
    background-color: #F6F6F6;
}

.job-dashbord{
    .bx-search-alt{
        font-size: 20px;
        top: 5px;
        right: 5px;
        color: #7E8384;
        position: absolute;
    }
    .row-header{
        padding: 15px 0px;
        background-color: $white;
        margin-left: 0px;
        margin-right: 0px;
        border-radius: 4px;
        border: 1px solid #E5E5E5;
    }
    .row-items{
        padding: 15px 0px;
        background-color: $white;
        margin-left: 0px;
        margin-right: 0px;
        border-radius: 4px;
        border: 1px solid #E5E5E5;
        &:hover{
            background: $light-bg-color;
        }
        .header-item{
            padding-left: 0px;
            padding-top: 0px;
            height: auto;
        }
        
        .dropdown-menu{
            box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.15);
            border-radius: 4px 0px 4px 4px;
            right: 25px !important;
            z-index: 1;
        }
        .dripicons-dot{
            position: absolute;
            font-size: 40px;
            top: -11px;
            left: 30px;
        }
        .text{
            margin-left: 30px;
        }
    }
    .job-w{
        width: 8% !important;
    }
    .total-interview-w,.email-w{
        width: 12%  !important;
    }
    .name-w{
        width:13%!important;
    }
}

.job-details{
    .top-container{
        background-color: white;
        border-radius: 4px;
        .ux-designer{
            color: white;
            padding-top: 2.5%;
            padding-left: 8%;
            background: linear-gradient(270.31deg, #98D9BB 0.51%, rgba(255, 255, 255, 0) 68.45%), #447E63;
            height: 70px;
            font-size: 20px;
            border-top-left-radius:4px;
            border-top-right-radius:4px;
        }
        .shift-image{
            margin-top: -38px;
        }
        .company-info{
            margin-top: -2.5%;
            margin-left: 2%;
            height: 60px;
        }
        .green-status-text{
            padding: 5px 10px;
            background-color: $light-green;
            color:$white;
            font-size: 11px;
            font-weight: 200;
            border-radius: 15px;
        }
        
    }
    .header-item{
        height: auto;
        padding-top: 0px;
    }
    .dropdown-menu{
        box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.15);
        border-radius: 4px 0px 4px 4px;
        right: 25px !important;
    }
    .card{
        border-radius: 0px;
        box-shadow: none;
        border: 1px solid #eeee;
        border-radius: 4px;
    }
    .skill-tags-container{
        display: inline-flex;
        .skill-tag{
            padding: 5px 10px;
            border-radius: 20px;
            text-align: center;
            background-color: #74B496;
            color: $white;
            margin-right: 5px;
            margin-bottom: 5px;
        }
    }
    .border-bottom-dark{
        border-bottom: 1px solid #e5e5ee;
    }
    
}
.job-tabs{
    padding: 25px;
    text-align: left;
    border: 1px solid #eeee;
    background-color: $white;
    border-radius: 4px;

    .nav-tabs{
        border-bottom: 1px solid $dark-green;
    }
    .nav-item {
        & .active{
            background-color: $dark-green;
            border:none;
            padding-top: 7px;
            padding-bottom: 7px;
            color:$white;
            &.nav-link{
                color: $white;
            }
        }
        & .nav-link{
            color:$dark-green;
            border: none;
            &:hover{
                border: none;
            }
        }
        margin-bottom: 0px;
        height:33px;
    }
}

.application-tab{
    .bx-search-alt{
        font-size: 20px;
        top: 5px;
        right: 5px;
        color: #7E8384;
        position: absolute;
    }
    .row-header{
        padding: 15px 0px;
        background-color: $white;
        margin-left: 0px;
        margin-right: 0px;
        border-radius: 4px;
        border: 1px solid #E5E5E5;
    }
    
    .row-items{
        padding: 15px 0px;
        background-color: $white;
        margin-left: 0px;
        margin-right: 0px;
        border-radius: 4px;
        border: 1px solid #E5E5E5;

        &:hover{
            background: $light-bg-color;
        }
        .header-item{
            padding-left: 0px;
            padding-top: 0px;
            height: auto;
        }
        .dropdown-menu{
            box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.15);
            border-radius: 4px 0px 4px 4px;
            right: 25px !important;
            z-index: 1;
        }
        .dripicons-dot{
            position: absolute;
            font-size: 40px;
            top: -11px;
            left: 0;
        }
        .text{
            margin-left: 10%;
        }
        .failed-text{
            color: #EB5757;
        }
        .pedning-text{
            color: #F2994A;
        }
    }
}
.no-result{
    background-color: $light-bg-color;
    font-size: 16px;
    padding: 15px 0px;
}
.detail-list{
    background-color: #fff;
    padding: 10px 5px;
    border-top: 1px solid #eeee;
}