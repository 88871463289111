.box-personal {
  background: #ffffff;
  height: 130px;
  border: 1px solid #eeeeee;
}
.btn-sp {
  outline: none !important;
  border: none !important;
  background-color: transparent !important;
}
.top-border-box-g {
  border: #dddddd;
}

.bg-verification-modal .modal-body {
  padding-right: 55px;
  padding-left: 55px;
  .bg-uplaod-btn {
    padding: 10px;
    & img {
      width: 14px;
    }
  }
  .pf-margin {
    margin-top: 21px;
  }
  .company-logo-img {
    width: 55px;
    position: relative;
    img {
      width: 40px;
      height: 40px;
      border-radius: 4px;
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
    }
    .img-close {
      cursor: pointer;
      width: 15px;
      height: 15px;
      background: #eb5757;
      border-radius: 100%;
      color: #fff;
      font-size: 15px;
      font-weight: 500;
      position: absolute;
      right: 8px;
      top: -5px;
      display: flex;
      text-align: center;
      justify-content: center;
      align-items: center;
    }
  }
  .mailtext {
    // position: absolute;
    width: 100%;

    /* Body 4 */

    font-family: Helvetica;
    font-size: 10px;
    line-height: 12px;
    /* identical to box height, or 120% */

    /* Green 1 */

    color: #213d30;
  }
  .mail {
    position: absolute;
    width: 100%;
    height: 44px;
    left: 314px;
    top: 474px;

    /* Grey 5 */

    background-color: #f6f6f6 !important;
    /* Grey 4 */

    border: 0.8px solid #e5e5e5;
    box-sizing: border-box;
    border-radius: 6px;
  }
  .emailchip {
    height: 20px;
    padding: 0 4px 0 1rem;
    display: inline-flex;
    align-items: center;
    margin: 0 0.3rem 0.3rem 0;
    background-color: #74b496;
    border-radius: 54px;
    font-family: Helvetica;
    font-size: 12px;
    line-height: 16px;
    /* identical to box height, or 133% */

    /* Grey 6 */

    color: #ffffff;

    .emailchiptext {
      display: flex !important;
      font-family: Helvetica !important;
      font-size: 12px !important;
      line-height: 16px;
      /* identical to box height, or 133% */

      /* Grey 6 */

      color: #ffffff !important;
    }

    .close {
      background-color: white;
      width: 22px;
      height: 22px;
      border-radius: 50%;
      border: none;
      cursor: pointer;
      font: inherit;
      margin-left: 10px;
      font-weight: bold;
      padding: 0;
      line-height: 1;
      align-items: center;
      justify-content: center;
    }
  }

  .error {
    margin: 0;
    font-size: 90%;
    color: tomato;
  }
}

.modal-footer {
  .cancelbtn {
    /* Grey 6 */
    font-family: Helvetica;
    font-style: normal;
    // font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height, or 125% */

    display: flex;
    align-items: center;
    text-align: center;

    /* Green 5 */

    color: #74b496;

    background-color: #ffffff !important;
    /* Green 5 */

    border: 1px solid #74b496 !important;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25) !important;
    border-radius: 4px !important;
  }

  .submitbtn {
    /* Green 5 */
    font-family: Helvetica;
    font-style: normal;
    // font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height, or 125% */

    display: flex;
    align-items: center;
    text-align: center;

    /* Grey 6 */

    color: #ffffff;

    /* Green 1 */

    background-color: #213d30;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
  }
}
